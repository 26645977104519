import * as React from "react"
import SiteContainer from "../../../components/site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import analytics from "../../../images/vectors/undraw_having_fun_re_vj4h.svg"
import measure_realtime from '../../../images/vectors/undraw_spreadsheet_re_cn18.svg'
import server_cluster from '../../../images/vectors/undraw_secure_server_re_8wsq.svg'
import PricingSection from "../../../components/PricingSection";
import CoreConcept from "../../../components/CoreConcept";
import PostgresFacebookAudienceWorkflow from "../../../components/workflows/PostgresFacebookAudienceWorkflow";

const IndexPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Increase ad conversions with better Audiences</title>
                <meta name="description" content="Build Audiences from any criteria, even offline data, and improve audience match rates in ad networks and external platforms to nearly 100%." />
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
            </Helmet>

            <nav className="bg-gray-200">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb breadcrumb-scroll">
                                <li className="breadcrumb-item">
                                    <span className="text-gray-700">
                                        Solutions
                                    </span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Audiences
                                </li>
                            </ol>

                        </div>
                    </div>
                </div>
            </nav>

            <section className="py-8 py-md-11 ">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} className="text-center">
                            <h1 className="display-1 fw-bold aos-init aos-animate" data-aos="fade-up"
                                data-aos-delay="50">Increase ad conversions with improved Audience accuracy.
                            </h1>
                            <p className="lead text-muted mb-6 aos-init aos-animate" data-aos="fade-up"
                               data-aos-delay="100">
                                Build Audiences from any criteria, even offline data, and improve audience match rates in ad networks and external platforms to nearly 100%.
                            </p>
                            <p className="mb-7 mb-md-9 aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                                <a className="btn btn-primary shadow lift" href="https://app.firstpartyhq.com/authentication/signup">
                                    Get Started for Free<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-14 bg-gray-800">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 mb-5 align-self-center">
                            <h3 className="text-gray-100">Get Started with a Workflow</h3>
                            <p className="text-gray-400">
                                Workflows guide you through configuring multiple Firstparty components to help achieve
                                an objective, and can help you solve marketing problems like analytics, attribution,
                                and audience creation in just a few clicks.
                            </p>
                            <a className="fw-bold text-white text-decoration-none" href="/workflows/">Explore All Workflows <i className="fe fe-arrow-right ms-1"></i></a>
                        </div>
                        <div className="col-12 col-md-8 mb-5">
                            <PostgresFacebookAudienceWorkflow />
                        </div>
                    </div>
                </div>
            </section>

            <div className="section bg-black pt-10 pt-md-10 pb-8 pb-md-11">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-10">
                        <div className="col order-md-2 text-center">

                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0"
                                 src={analytics} alt="..." />

                        </div>
                        <div className="col-12 col-md-7 order-md-1">

                            <h2 className="text-white">
                                Create Audiences from any source, including offline data
                            </h2>

                            <div className="lead text-muted-80 mb-0">
                                Create Segments of Events and build Audiences of individuals from web activity combined with
                                data from other marketing tools, CRM, internal databases, and more.
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">

                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">

                                            <a className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1"
                                               href="#!">
                                                <span>1</span>
                                            </a>

                                        </div>
                                        <div className="col">

                                            <hr className="d-none d-md-block bg-gray-900-50 me-n7" />

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">

                                    <h3 className="text-white">
                                        Decentralized Management
                                    </h3>

                                    <p className="text-muted-80 mb-6 mb-md-0">
                                        Create audiences from any criteria, maintain one Audience definition, sync to multiple ad networks
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">

                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">

                                            <a href="#!"
                                               className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1">
                                                <span>2</span>
                                            </a>

                                        </div>
                                        <div className="col">

                                            <hr className="d-none d-md-block bg-gray-900-50 me-n7" />

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">

                                    <h3 className="text-white">
                                        Improved Accuracy
                                    </h3>

                                    <p className="text-muted-80 mb-6 mb-md-0">
                                        Improve audience match rates to nearly 100% with the addition of offline data (e.g. Salesforce opportunities)
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">

                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">

                                            <a href="#!"
                                               className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1">
                                                <span>3</span>
                                            </a>

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">

                                    <h3 className="text-white">
                                        Increased Conversions
                                    </h3>

                                    <p className="text-muted-80 mb-0">
                                        Sync Firstparty Audiences to Facebook Ads, and combine existing Segment definitions to build new Audiences
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="pt-10 pt-md-15 pb-10 pb-md-14 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-10 text-center">
                            <h6 className="text-uppercase text-primary">
                                Analytics
                            </h6>
                            <h2>Measure Audience Response with Firstparty Analytics</h2>
                            <p className="fs-lg text-gray-700 pb-5">
                                Firstparty collects, processes, stores, and lets you query web analytics data in realtime. All data can be stored on your infrastructure, and everything can be served from your domain.
                            </p>
                            <a href="/solutions/analytics/" className="btn btn-primary-soft">Explore Analytics</a>
                        </div>
                    </div>
                </div>
            </section>

            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x shape-flip-x text-light">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="py-8 py-md-11 pt-md-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-5">
                            <CoreConcept
                                title="Segments define limited sets of Events, Sessions, and Profiles."
                                documentation_link="/docs/segments/overview/"
                                api_link="/docs/api/reference/#tag/Segments"
                                compact
                            >
                                <p className="text-gray-400">
                                    Segments are limited to a single Item type of either Events, Sessions, or Profiles, and may be used later on to create Audiences from multiple Segment types.
                                </p>
                            </CoreConcept>

                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <CoreConcept
                                title="Audiences define a set of Profiles who are all part of one or more specific Segments."
                                documentation_link="/docs/audiences/overview/"
                                api_link="/docs/api/reference/#tag/Audiences"
                                compact
                            >
                                <p className="text-gray-400">
                                    Audiences may be sent to Destinations to keep external marketing platforms in sync with an identical set of member Profiles.
                                </p>
                            </CoreConcept>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-8 pb-md-11">
                <Container>
                    <Row className="justify-content-between align-items-center">
                        <Col md={6} lg={6} className="order-md-2">
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0">
                                <img src={measure_realtime} />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1 pt-md-12">
                            <h6 className="text-uppercase text-primary">
                                Improve Audience accuracy
                            </h6>
                            <h2>
                                Match Beyond Spreadsheets
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Your customers and future customers interact with your business on your website and beyond.
                                Firstparty's novel first-party Event collection lets you build Audiences in ad networks
                                based on known IDs - not just hashed email addresses.
                            </p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary">
                                            Firstparty aliases known IDs to ad networks for each visitor
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary">
                                            Audiences sync to Facebook Ads and more based on those known IDs
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <span className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </span>
                                        <p className="text-primary">
                                            Facebook Audience match rates are double or triple when compared to matching on Email address alone
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-11">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6 img-skewed img-skewed-end">
                            <div className="card rounded-lg bg-black mb-6 mb-md-0 img-skewed-item screenshot">
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code className="highlight hljs"
                                          data-typed="{&quot;backSpeed&quot;:2, &quot;strings&quot;: [&quot;>&nbsp;$&nbsp;npm&nbsp;install<br/><span class=\&quot;text-success\&quot;>Everything&nbsp;is&nbsp;installed</span><br/><br/>>&nbsp;$&nbsp;npm start<br/><span class=\&quot;text-success\&quot;>scss&nbsp;watching</span><br/><span class=\&quot;text-success\&quot;>LiveReload&nbsp;started</span><br/><span class=\&quot;text-success\&quot;>Opening&nbsp;localhost:8080</span><br/><br/>>&nbsp;$&nbsp;that’s&nbsp;it<br/><span class=\&quot;text-success\&quot;>Yup,&nbsp;that’s&nbsp;it.</span>&quot;]}"
                                          style={{minHeight: "183px"}}>
                                        <span className="text-success">// Track pageviews with one line of Javascript</span>
                                        <br /><br />
                                        firstparty.page()
                                        <br /><br />
                                        <br /><br />
                                        <span className="text-success">// Easily track custom events</span>
                                        <br /><br />
                                        firstparty.track('Form Completed', &#123;
                                        <br />
                                        &nbsp;&nbsp;email: 'customer@gmail.com'
                                        <br />
                                        &#125;)
                                        <br />
                                    </code>
                                    <span className="typed-cursor" aria-hidden="true">|</span>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 offset-md-1">
                            <h6 className="text-uppercase text-primary">
                                Decentralize audience management
                            </h6>
                            <h2>Maintain a Single Audience Definition</h2>
                            <p className="fs-lg text-gray-700">
                                Maintain a single audience definition that automatically syncs to external ad networks. Firstparty uses internal IDs to identify individuals, allowing you to create an audience segment once and constantly update any supported ad network - often in near realtime.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-between align-items-top">
                        <Col md={6} lg={5} className="order-md-2">
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0">
                                <img src={server_cluster} />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1">
                            <h6 className="text-uppercase text-primary">
                                Improve Audience accuracy
                            </h6>
                            <h2>
                                Create audiences in ad networks without sharing customer data
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Build ad audiences of exactly who you want to include (or exclude) without sharing any personal information or browsing history with the ad network. Firstparty audiences are based on any data or criteria you have, then syncs obfuscated IDs to ad networks - ensuring audience accuracy and complete data privacy.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage
