import React from 'react'
import {Link} from "gatsby";

export default (props) => (
    <section className="pt-9 pb-9 pt-md-12 pb-md-12 bg-light">
        <div className="container">
            <div className="row justify-content-center pb-5 pb-md-10">
                <div className="col-12 col-md-10 col-lg-8 text-center">
                    <h1>
                        Collect 1,000,000 Events for Free
                    </h1>

                    <p className="lead text-gray-700">
                        Pricing is based on how many events you collect.
                        Start paying after you use your 1,000,000 free events.
                        No contract or credit card required to get started.
                    </p>
                    <div className="alert alert-secondary" role="alert">
                        Rule invocations are free until May 2022
                    </div>
                </div>
            </div>
            <div className="row align-items-center gx-0">
                <div className="col-12 col-md-6">

                    <div className="card rounded-lg shadow-lg mb-6 mb-md-0" style={{zIndex: '100'}}>

                        <div className="card-body py-6 py-md-8">
                            <div className="row justify-content-center">
                                <div className="col-12 col-xl-9">

                                    <div className="text-center mb-5">
                                                <span className="badge rounded-pill bg-primary-soft">
                                                <span className="h6 fw-bold text-uppercase">Pay As You Go</span>
                                                </span>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <span className="h2 mb-0 mt-2">$</span>
                                        <span className="price display-2 mb-0">0.00005</span>
                                    </div>

                                    <p className="text-center text-muted mb-6 mb-md-8">
                                        per event
                                    </p>

                                    <div className="d-flex">

                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>

                                        <p>
                                            Unlimited data Sources
                                        </p>

                                    </div>
                                    <div className="d-flex">

                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>

                                        <p>
                                            Unlimited data warehouses
                                        </p>

                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            No credit card required for trial
                                        </p>
                                    </div>

                                    <p className="text-center text-muted mt-5">
                                        Billed monthly after your 1,000,000 free events are used
                                    </p>

                                </div>
                            </div>
                        </div>

                        <a href="https://app.firstpartyhq.com/authentication/signup" className="card-btn btn w-100 btn-lg btn-primary">
                            Get Started for Free
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 ms-md-n3">

                    <div className="card rounded-lg shadow-lg aos-init aos-animate" data-aos="fade-up"
                         data-aos-delay="200">

                        <div className="card-body py-6 py-md-8">
                            <div className="row justify-content-center">
                                <div className="col-12 col-xl-10">

                                    <p className="text-center mb-8 mb-md-11">
                                                <span className="badge rounded-pill bg-primary-soft">
                                                <span className="h6 fw-bold text-uppercase">Enterprise</span>
                                                </span>
                                    </p>

                                    <p className="lead text-center text-muted mb-0 mb-md-10">
                                        Volume discounts are available starting at 10,000,000 events per month
                                        on an annual basis.
                                    </p>

                                </div>
                            </div>
                        </div>

                        <Link to="/contact" className="card-btn btn w-100 btn-lg btn-light btn-gray-600">
                            Contact Us
                        </Link>

                    </div>

                </div>
            </div>
        </div>
    </section>

)