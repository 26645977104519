import React from 'react'

export default (props) => {

    const card = <div className="card bg-gray-900 h-100">
        <div className="card-body d-flex flex-column h-100 justify-between">
            <div className="row justify-content-center">
                <div className={"col-12"}>
                    <span className="badge rounded-pill bg-primary mb-3">
                        <span className="h6 text-uppercase">Core Concept</span>
                    </span>
                    <div className="row">
                        <div className={props.compact ? "col-12" : "col-12 col-lg-10"}>
                            <h3 className="text-white">
                                {props.title}
                            </h3>
                            {props.children}
                        </div>
                    </div>
                    <div className="d-grid d-md-flex mt-6">
                        <a href={props.documentation_link} className="btn btn-white-soft btn-sm shadow lift mb-3 mb-md-0 me-0 me-md-3">
                            Documentation <i className="fe fe-arrow-right d-none d-md-inline ms-1" />
                        </a>
                        <a href={props.api_link} className="btn btn-primary btn-sm lift">
                            API Reference
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    if(props.compact) {
        return card;
    }

    return <section className="py-8 py-md-11">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {card}
                </div>
            </div>
        </div>
    </section>
}
