import React from "react"
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import dictionary from "../../dictionary";

export default function WorkflowCard({recipe}) {

    return (
        <div className="card mb-6 mb-lg-0 shadow-light-lg">
            <a className="card-body mt-auto" href={`/workflows/${recipe.slug}`}>
                <h6 className="text-uppercase text-primary">Workflow - {recipe.total_steps} Steps</h6>
                <h2 className="text-black">
                    {recipe.name}
                </h2>
                <hr className="card-meta-divider" />
                <div className="mb-0 pb-0 text-muted">
                    <ReactMarkdown
                        children={recipe.content}
                        remarkPlugins={[remarkGfm]}
                    />
                </div>
            </a>
            <a className="card-meta" href={`/workflows/${recipe.slug}`}>
                <hr className="card-meta-divider" />
                <div className="d-flex flex-row">
                    {recipe.steps.map(step => {
                        if(step.object !== 'content') {
                            return <span className="me-3 text-gray-800">
                            {React.createElement(dictionary[step.object].icon, { style: { display: 'flex' } })}
                        </span>
                        }
                    })}
                </div>
                <a href={`/workflows/${recipe.slug}`} className="btn btn-sm btn-primary-soft ms-auto">View Workflow</a>
            </a>
        </div>
    )
}